import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/mnt/fstack_dev/nykenshinkai.com/nyksk/src/components/default-mdx-layout.js";
import SEO from "../components/seo";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Beginner's Guide" mdxType="SEO" />
    <h1>{`What is Kendo?`}</h1>
    <h2><OutboundLink href="https://www.auskf.org/info/what-is-kendo" mdxType="OutboundLink">{`All US Kendo Federation - What is Kendo?`}</OutboundLink></h2>
    <blockquote>
      <p parentName="blockquote">{`Kendo, is the art of Japanese fencing. "Ken" or tsurugi is from the character meaning sword. The character for "Do" or michi includes the meaning way or path which translates as "The way of the sword". A path in life which is followed through the training of kendo.`}</p>
    </blockquote>
    <h2><a parentName="h2" {...{
        "href": "https://en.wikipedia.org/wiki/Kendo"
      }}>{`Wikipedia`}</a></h2>
    <blockquote>
      <p parentName="blockquote">{`Kendo (剣道 kendō, lit. "sword way") is a traditional Japanese martial art,`}<br parentName="p"></br>{`
`}{`which descended from swordsmanship (kenjutsu) and uses bamboo swords (shinai) and protective armour (bōgu).`}<br parentName="p"></br>{`
`}{`Today, it is widely practiced within Japan and many other nations across the world.`}</p>
      <p parentName="blockquote">{`Kendo is an activity that combines martial arts practices and values with strenuous sport-like physical activity.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6c66c9adff06c0d3f18917bf4a6dc101/e1596/mentsuke.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6c66c9adff06c0d3f18917bf4a6dc101/8ac56/mentsuke.webp 240w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/d3be9/mentsuke.webp 480w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/e46b2/mentsuke.webp 960w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/f992d/mentsuke.webp 1440w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/882b9/mentsuke.webp 1920w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/8df42/mentsuke.webp 2048w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6c66c9adff06c0d3f18917bf4a6dc101/09b79/mentsuke.jpg 240w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/7cc5e/mentsuke.jpg 480w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/6a068/mentsuke.jpg 960w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/644c5/mentsuke.jpg 1440w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/0f98f/mentsuke.jpg 1920w", "/static/6c66c9adff06c0d3f18917bf4a6dc101/e1596/mentsuke.jpg 2048w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6c66c9adff06c0d3f18917bf4a6dc101/6a068/mentsuke.jpg",
              "alt": "Students putting on helmet",
              "title": "Students putting on helmet",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For the unacquainted, kendo may appear violent and unforgiving. Beyond that initial impression is a harmony between sports and martial arts.`}</p>
    <p>{`There are four targets in kendo: kote (right forearm), men (head), do (left & right body), and tsuki (throat). The main objective is to create an opportunity and then strike a target. Opportunities manifest as one of four human weaknesses: surprise, fear, doubt, or confusion. Strikes which are missing intention, proper form, or spirit are not considered valid.`}</p>
    <p>{`Each successful strike is a valuable lesson for both parties to appreciate. The attacker gains confirmation that an opportunity existed and executed a successful strike.`}<br parentName="p"></br>{`
`}{`The receiver realizes they experienced a moment of weakness. Since no one died, we can help each other improve.`}</p>
    <p>{`Kendo begins and ends with rei ("respectful bow"). At the heart of Japanese culture is a deep consideration for others. Because people of all ages (5 to 100) with varying levels of experience practice kendo together, we must consider how to effectively bring out the best of each other's abilities.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/44cb48f932bf0fd34de5845c60bd7157/e1596/whitekeikogi-back.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/44cb48f932bf0fd34de5845c60bd7157/8ac56/whitekeikogi-back.webp 240w", "/static/44cb48f932bf0fd34de5845c60bd7157/d3be9/whitekeikogi-back.webp 480w", "/static/44cb48f932bf0fd34de5845c60bd7157/e46b2/whitekeikogi-back.webp 960w", "/static/44cb48f932bf0fd34de5845c60bd7157/f992d/whitekeikogi-back.webp 1440w", "/static/44cb48f932bf0fd34de5845c60bd7157/882b9/whitekeikogi-back.webp 1920w", "/static/44cb48f932bf0fd34de5845c60bd7157/8df42/whitekeikogi-back.webp 2048w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/44cb48f932bf0fd34de5845c60bd7157/09b79/whitekeikogi-back.jpg 240w", "/static/44cb48f932bf0fd34de5845c60bd7157/7cc5e/whitekeikogi-back.jpg 480w", "/static/44cb48f932bf0fd34de5845c60bd7157/6a068/whitekeikogi-back.jpg 960w", "/static/44cb48f932bf0fd34de5845c60bd7157/644c5/whitekeikogi-back.jpg 1440w", "/static/44cb48f932bf0fd34de5845c60bd7157/0f98f/whitekeikogi-back.jpg 1920w", "/static/44cb48f932bf0fd34de5845c60bd7157/e1596/whitekeikogi-back.jpg 2048w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/44cb48f932bf0fd34de5845c60bd7157/6a068/whitekeikogi-back.jpg",
              "alt": "Sensei Advice",
              "title": "Sensei Advice",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Kendo at NY Kenshinkai`}</h2>
    <p>{`During all practices, visitors and beginners without full armor are always accompanied by either a junior or senior instructor.`}<br parentName="p"></br>{`
`}{`The instructors will guide you and are happy to answer any questions you may have.`}</p>
    <p>{`Tuesday classes are open for experienced members and are focused on basic fundamentals.`}<br parentName="p"></br>{`
`}{`The first hour is usually spent without wearing full armor.`}<br parentName="p"></br>{`
`}{`Basic motions are deconstructed and repeated.`}<br parentName="p"></br>{`
`}{`Experienced members put on full armor and continue fundamentals practice.`}<br parentName="p"></br>{`
`}{`Some time is left at the end for open sparring.`}</p>
    <p>{`Friday classes are open for beginners and visitors from other kendo dojos.`}<br parentName="p"></br>{`
`}{`Beginners and experienced members are sometimes separated, sometimes mixed (depends on experience and safety).`}<br parentName="p"></br>{`
`}{`Experienced members put on full armor and begin fundamentals practice.`}<br parentName="p"></br>{`
`}{`After the first hour, the beginner's session is complete.`}<br parentName="p"></br>{`
`}{`Members with full armor continue with open sparring.`}</p>
    <Button variant="contained" color="primary" size="large" component={Link} to="/class-information/" endIcon={<NavigateNextIcon mdxType="NavigateNextIcon" />} mdxType="Button">
  Class Information
    </Button>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/13c01946d6f322f4e484cd3e9922903c/e1596/aimen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/13c01946d6f322f4e484cd3e9922903c/8ac56/aimen.webp 240w", "/static/13c01946d6f322f4e484cd3e9922903c/d3be9/aimen.webp 480w", "/static/13c01946d6f322f4e484cd3e9922903c/e46b2/aimen.webp 960w", "/static/13c01946d6f322f4e484cd3e9922903c/f992d/aimen.webp 1440w", "/static/13c01946d6f322f4e484cd3e9922903c/882b9/aimen.webp 1920w", "/static/13c01946d6f322f4e484cd3e9922903c/8df42/aimen.webp 2048w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/13c01946d6f322f4e484cd3e9922903c/09b79/aimen.jpg 240w", "/static/13c01946d6f322f4e484cd3e9922903c/7cc5e/aimen.jpg 480w", "/static/13c01946d6f322f4e484cd3e9922903c/6a068/aimen.jpg 960w", "/static/13c01946d6f322f4e484cd3e9922903c/644c5/aimen.jpg 1440w", "/static/13c01946d6f322f4e484cd3e9922903c/0f98f/aimen.jpg 1920w", "/static/13c01946d6f322f4e484cd3e9922903c/e1596/aimen.jpg 2048w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/13c01946d6f322f4e484cd3e9922903c/6a068/aimen.jpg",
              "alt": "Kendo players striking each other at same time",
              "title": "Kendo players striking each other at same time",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      